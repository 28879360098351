import React from "react";
import theme from "theme";
import { Theme, Text, Image, Button, Box, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"index"} />
		<Helmet>
			<title>
				Головна | RenovateRight
			</title>
			<meta name={"description"} content={"Трансформуємо простір, покращуємо життя"} />
			<meta property={"og:title"} content={"Головна | RenovateRight"} />
			<meta property={"og:description"} content={"Трансформуємо простір, покращуємо життя"} />
			<meta property={"og:image"} content={"https://progresservise.com/images/image1.jpg"} />
			<link rel={"shortcut icon"} href={"https://progresservise.com/images/icon.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://progresservise.com/images/icon.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://progresservise.com/images/icon.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://progresservise.com/images/icon.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://progresservise.com/images/icon.png"} />
			<meta name={"msapplication-TileImage"} content={"https://progresservise.com/images/icon.png"} />
			<meta name={"msapplication-TileColor"} content={"#ffffff"} />
		</Helmet>
		<Components.Header />
		<Section padding="60px 0 70px 0" md-padding="80px 0 90px 0" quarkly-title="Hero-9">
			<Box
				min-width="100px"
				min-height="100px"
				lg-width="100%"
				display="flex"
				flex-direction="column"
				align-items="center"
			>
				<Text
					margin="0px 0px 20px 0px"
					font="normal 700 64px/1.2 --fontFamily-sansTrebuchet"
					sm-font="normal 700 42px/1.2 --fontFamily-sans"
					padding="0px 200px 0px 200px"
					text-align="center"
					color="--darkL1"
					lg-padding="0px 0 0px 0"
				>
					RenovateRight
				</Text>
				<Image
					src="https://progresservise.com/images/image1.jpg"
					display="block"
					width="100%"
					max-height="300px"
					object-fit="cover"
					margin="0px 0px 25px 0px"
					border-radius="15px"
				/>
				<Text
					margin="0px 0px 50px 0px"
					font="--lead"
					color="#50555a"
					padding="0px 60px 0px 60px"
					text-align="center"
					lg-padding="0px 0 0px 0"
				>
					Ласкаво просимо до RenovateRight, вашого надійного партнера у перетворенні вашого житлового простору на будинок вашої мрії. Ми спеціалізуємося на ремонтах, які не лише покращують красу та функціональність вашої оселі, але й підвищують вартість вашого майна. Наша команда прагне надавати персоналізовані рішення, які відповідають вашим унікальним потребам і перевершують ваші очікування.
				</Text>
				<Button
					margin="0px 15px 0px 0px"
					padding="12px 28px 12px 28px"
					background="--color-green"
					border-radius="8px"
					font="normal 400 17px/1.5 --fontFamily-sans"
					sm-margin="0px 0 15px 0px"
					transition="background-color 0.2s ease 0s"
					border-width="2px"
					border-style="solid"
					border-color="--color-green"
					hover-background="rgba(63, 36, 216, 0)"
					hover-color="--darkL1"
					href="/contact-us"
					type="link"
					text-decoration-line="initial"
				>
					Замовити попередній огляд
				</Button>
			</Box>
		</Section>
		<Section padding="80px 0 80px 0" sm-padding="60px 0 60px 0">
			<Box
				padding="80px 72px 80px 72px"
				background="rgba(214, 255, 213, 0.7)"
				border-radius="24px"
				margin="0px 0px 64px 0px"
				sm-padding="60px 36px 60px 36px"
			>
				<Box
					width="100%"
					display="flex"
					flex-direction="column"
					md-width="100%"
					md-align-items="center"
					md-justify-content="center"
					md-text-align="center"
					lg-width="100%"
				>
					<Text
						margin="0px 0px 16px 0px"
						font="--headline2"
						color="--dark"
						text-align="center"
						sm-font="normal 700 32px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					>
						Про компанію
					</Text>
					<Text margin="0px 0px 0px 0px" font="--base" color="--greyD3" md-text-align="center">
						У RenovateRight ми віримо, що кожна оселя має потенціал. Маючи більш ніж десятирічний досвід, наші кваліфіковані фахівці здатні впоратися з усіма аспектами ремонту будинків, квартир і котеджів. Від початкової консультації по дизайну до останніх штрихів, ми прагнемо забезпечити безперебійний і безпроблемний процес. Ми використовуємо високоякісні матеріали та найсучасніші технології, які гарантують, що ваш відремонтований будинок буде не лише красивим, але й стійким та довговічним.
					</Text>
				</Box>
			</Box>
			<Box
				display="grid"
				lg-flex-wrap="wrap"
				grid-template-columns="repeat(3, 1fr)"
				grid-gap="36px 4%"
				lg-grid-template-columns="repeat(2, 1fr)"
				sm-grid-template-columns="1fr"
				sm-grid-gap="12px"
			>
				<Box display="flex" flex-direction="column" sm-margin="0px 0px 30px 0px" sm-width="100%">
					<Text margin="0px 0px 18px 0px" color="--darkL2" font="--headline3" lg-text-align="left">
						Індивідуальні рішення
					</Text>
					<Text margin="0px 0px 0px 0px" color="--greyD2" font="--base" lg-text-align="left">
						Кожен простір унікальний, як і ваші потреби. Ми пропонуємо індивідуальні плани ремонту, які ідеально відповідають вашому стилю та функціональним вимогам.
					</Text>
				</Box>
				<Box display="flex" flex-direction="column" sm-width="100%" sm-margin="0px 0px 30px 0px">
					<Text margin="0px 0px 18px 0px" color="--darkL2" font="--headline3" lg-text-align="left">
						Якісна майстерність
					</Text>
					<Text margin="0px 0px 0px 0px" color="--greyD2" font="--base" lg-text-align="left">
						Наша команда складається з досвідчених майстрів, які пишаються своєю роботою, гарантуючи, що кожна деталь буде досконалою для вашого задоволення.
					</Text>
				</Box>
				<Box display="flex" flex-direction="column" sm-margin="0px 0px 30px 0px" sm-width="100%">
					<Text margin="0px 0px 18px 0px" color="--darkL2" font="--headline3" lg-text-align="left">
						Прозорі процеси
					</Text>
					<Text margin="0px 0px 0px 0px" color="--greyD2" font="--base" lg-text-align="left">
						Ми інформуємо вас протягом усього процесу реконструкції, забезпечуючи прозорість і підзвітність послуг.
					</Text>
				</Box>
			</Box>
		</Section>
		<Section
			padding="60px 0 60px 0"
			sm-padding="40px 0 40px 0"
			min-height="400px"
			sm-min-height="auto"
			background="--color-light"
			display="flex"
			flex-direction="column"
		>
			<Override slot="SectionContent" flex-direction="row" gap="30px" flex-wrap="wrap" />
			<Text
				as="h2"
				padding="0px 20px 16px 20px"
				font="normal 900 32px/1.2 --fontFamily-sans"
				color="--dark"
				max-width="850px"
				width="50%"
				md-width="100%"
			>
				Давайте побудуємо будинок вашої мрії разом!
			</Text>
			<Text
				margin="0px"
				font="--base"
				color="--grey"
				width="50%"
				md-width="100%"
				md-margin="16px 0px 0px 0px"
				sm-margin="16px 0px 32px 0px"
			>
				З RenovateRight розпочніть подорож по ремонту, де ваше бачення стане реальністю. Зв'яжіться з нами вже сьогодні, і ми допоможемо вам перетворити будинок вашої мрії на справжню насолоду. Ваш ідеальний житловий простір знаходиться на відстані одного дзвінка або електронного листа.
			</Text>
			<Box
				align-self="center"
				display="flex"
				width="100%"
				justify-content="space-around"
				margin="25px 0px 0px 0px"
			>
				<Image src="https://progresservise.com/images/image2.jpg" display="block" width="45%" />
				<Image src="https://progresservise.com/images/image3.jpg" display="block" width="45%" />
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});